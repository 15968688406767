const uid_data = {
  test: {
    trunkRoad_autoTrace: [
      '245290638',
      '280044285', //18400000003
      '859718553269', //房玉博
      '137455882', //admin
    ],
    sattlement_receivable: [
      '245290638',
      '27892950', //18400000002
      '859718553269', //房玉博
      '137455882', //admin
    ],
    sattlement_payable: [
      '245290638', //18400000001
      '27892950',
      '859718553269', //房玉博
      '137455882', //admin
    ],
    allow_orderDetail_preDetail: [
      '245290638', //18400000001
      '859718553269', //房玉博
      '137455882', //admin
    ],
    // 报价价格是否ok操作
    allow_askBidList_priceSure: [
      '245290638', //18400000001
      '859718553269', //房玉博
      '137455882', //admin
    ],
    allow_orderDetail_total: [
      '245290638',
      '488146086931', //15934115579
      '859718553269', //房玉博
      '137455882', //admin
      '171267320',
    ],
    allow_order_cli_edit: [
      '245290638',
      '859718553269', //房玉博
      '137455882', //admin
    ],
    // 23.7.20新加权限
    business: [
      //应收应付列表的导出，导入，编辑
      '245290638', //18400000001
      '859718553269', //房玉博
      '137455882', //admin
    ],
    client_block: [
      //客户资源池/客户信息管理/客户信息管理审核
      // "27892950", //18400000002
      '245290638',
      '859718553269', //房玉博
      '137455882', //admin
    ],
    client_block_infoVerify: [
      '27892950',
      '137455882', //admin
    ],
    insurance_page: [
      //保险页面
      '280044285', //18400000003
      '245290638',
      '241109654',
      '859718553269', //房玉博
      '137455882', //admin
    ],
    allow_orderPay_price_change: [
      '280044285',
      '859718553269', //房玉博
      '137455882', //admin
    ],
    // 询价列表和自动报价列表导出
    ask_dataout_button: [
      '27892950', //18400000002
      '859718553269', //房玉博
      '137455882', //admin
    ],
    order_list_bidtype_change: [
      '27892950', //18400000002
      '859718553269', //房玉博
      '137455882', //admin
    ],
    change_task: [
      '27892950',
      '137455882', //admin
      '859718553269', //房玉博
    ],
    allow_multi_change_price: [
      '27892950',
      '137455882', //admin
      '859718553269', //房玉博
    ],
    binding_change_customer: [
      '27892950', //18400000002
      '859718553269', //房玉博
      '137455882', //admin
    ],
    edit_purchase_order_price: [
      '27892950', //18400000002
      '859718553269', //房玉博
    ],
  },
  prod: {
    // "" 小祥测试
    // "241109654" admin
    trunkRoad_autoTrace: [
      //允许看报价计算页面
      '361270142709', //陈希
      '735087994250',
      '830064255807',
      '240259392750',
      '494400994449',
      '295982817862',
      '241109654',
      '346585654786', //房玉博
      '190193346216', //15110040839张贺嘉
    ],
    sattlement_receivable: [
      //允许看应收页面(财务)
      '301822004530',
      '666530594323',
      '361270142709',
      '735087994250',
      '830064255807',
      '240259392750',
      '494400994449',
      '295982817862',
      '241109654',
      '346585654786', //房玉博
      '190193346216', //15110040839张贺嘉
    ],
    sattlement_payable: [
      //允许看应付页面(财务，服务中心)
      //服务中心侧
      '110174647780',
      '269621357895',
      '926357219994',
      '983436886485',
      '727480981846',
      //其他可以看应付
      '301822004530',
      '666530594323',
      '361270142709',
      '735087994250',
      '830064255807',
      '240259392750',
      '494400994449',
      '295982817862',
      '241109654',
      '346585654786', //房玉博
      '190193346216', //15110040839张贺嘉
    ],
    //询价详情其他用户只能看总价
    allow_orderDetail_preDetail: [
      //允许看询价详情基础报价(提干送，服务中心)
      '110174647780',
      '269621357895',
      '926357219994',
      '983436886485',
      '727480981846',
      '125838826', //宋向阳
      '778195485552', //陈冲
      '241109654',
      '346585654786', //房玉博
      '256472279', //李艳超18002132991
      '653159735340', //李艳超18502201983
      '361270142709',
      '625462569748',
      '171267320',
      '190193346216', //15110040839张贺嘉
    ],
    // 报价价格是否ok操作
    allow_askBidList_priceSure: [
      '125838826', //宋向阳
      '778195485552', //陈冲
      '346585654786', //房玉博
      '27892950', //18400000002
      '361270142709',
      '625462569748', //宫朝渊
      '190193346216',
    ],
    allow_orderDetail_total: [
      //允许看询价详情全部报价(客服)
      '494400994449',
      '361270142709',
      '735087994250',
      '830064255807',
      '240259392750',
      '295982817862',
      '292650007098',
      '241109654',
      '346585654786', //房玉博
      '256472279', //李艳超18002132991
      '653159735340', //李艳超18502201983
      '171267320',
      '190193346216',
    ],
    //23.7.20新加权限限制
    business: [
      //应收应付列表的导出，导入，编辑
      '301822004530', //胡雯靖
      '666530594323', //高树超
      '241109654', //admin
      '240259392750', //袁兴刚
      '346585654786', //房玉博
      '361270142709',
    ],
    client_block: [
      //客户资源池/客户信息管理
      '256472279',
      '670863489006',
      '361270142709',
      '240259392750',
      '494400994449',
      '173118405196',
      '653159735340', //李艳超18502201983
      '292650007098',
      '241109654',
      '346585654786', //房玉博
      '171267320',
      '190193346216',
      '968501411010', //何嘉雯
    ],
    client_block_infoVerify: [
      //客户信息管理审核
      '256472279', //李艳超18002132991
      '670863489006',
      '361270142709',
      '494400994449',
      '173118405196',
      '653159735340',
      '292650007098',
      '301822004530', //胡雯靖
      '666530594323', //高树超
      '241109654', //admin
      '240259392750', //袁兴刚
      '171267320',
      '190193346216',
    ],
    insurance_page: [
      //保险页面
      '361270142709',
      '240259392750',
      '494400994449',
      '301822004530',
      '241109654',
      '346585654786', //房玉博
      '171267320', //13231953506
    ],
    allow_order_cli_edit: [
      //允许订单列表修改客户名称
      '241109654',
      '346585654786', //房玉博
      '361270142709',
      '171267320',
    ],
    allow_orderPay_price_change: [
      //允许运费结算跟踪改价
      '830064255807',
      '125838826', //宋向阳
      '241109654',
      '346585654786', //房玉博
      '361270142709',
      '171267320',
      '190193346216',
    ],
    // 询价列表和自动报价列表导出
    ask_dataout_button: [
      '241109654',
      '346585654786', //房玉博
      '361270142709',
      '171267320',
      '190193346216',
    ],
    order_list_bidtype_change: [
      '346585654786', //房玉博
      '241109654', //admin
      '361270142709',
      '190193346216',
    ],
    change_task: [
      '125838826', //宋向阳
      '171267320',
      '241109654', //admin
      '361270142709',
      '190193346216',
      '256472279', //李艳超18002132991
      '653159735340', //李艳超18502201983
    ],
    //允许批量修改 应收应付价格
    allow_multi_change_price: [
      '241109654', //admin
      '346585654786', //房玉博
      '171267320',
      '190193346216',
    ],
    //客户信息管理代下单业务员变更
    binding_change_customer: [
      '241109654', //admin
      '346585654786', //房玉博
      '171267320',
      '968501411010', //何嘉雯
    ],
    edit_purchase_order_price: [
      '241109654', //admin
      '346585654786', //房玉博
      '190193346216', //15110040839张贺嘉
    ],
  },
};

export const uid_auth = function (dev: string = 'test', uid: string): string[] {
  if (!uid) return [];
  let data = uid_data[dev];
  if (!data) data = dev == 'prod' ? uid_data['prod'] : uid_data['test'];

  const auth = [];
  const auth_keys = Object.keys(data) as string[];
  const auth_values = Object.values(data) as string[][];
  for (let i = 0; i < auth_values.length; i++) {
    const flag = auth_values[i].some((item) => item === uid);
    if (flag) auth.push(auth_keys[i]);
  }
  return auth;
};
